<template>
  <div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-container v-if="detail">
        <b-row>
          <b-col>
            <b-card title="Order Details">
              <b-list-group>
                <b-list-group-item><span class="font-weight-bold">Total time: </span> {{ detail.details.totalTime }} min</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Total amount: </span> £{{ detail.details.totalPrice }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Status:&nbsp;&nbsp;</span>
                  <b-badge
                    pill
                    :variant="detail.details.isCompleted ? 'success':'danger'"
                  >
                    {{ detail.details.isCompleted ? 'Completed': 'Pending' }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.details.mobileTablet">
          <b-col
            v-for="(item, index) in detail.details.mobileTablet"
            :key="index"
          >
            <b-card title="Devices">
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-start">
                    <div>
                      <b-img
                        fluid
                        :src="item.color.image"
                      />
                    </div>
                    <div>
                      <p class="font-weight-bold">
                        {{ item.model.name }} {{ item.color.name }}
                      </p>
                      <p
                        v-for="issue in item.issues"
                        :key="issue.id"
                      >
                        {{ issue.name }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.details.businessSupport.length">
          <b-col>
            <b-card title="Business Support">
              <b-list-group>
                <b-list-group-item>Session Time: {{ detail.details.businessSupport[0].session.time }}</b-list-group-item>
                <b-list-group-item>Session Price: {{ detail.details.businessSupport[0].session.price }}</b-list-group-item>
              </b-list-group>
              <p class="mt-2 font-weight-bold">
                Issues
              </p>
              <ul>
                <li
                  v-for="issue in detail.details.businessSupport[0].issues"
                  :key="issue.id"
                >
                  {{ issue.name }} ({{ issue.average_time }})
                </li>
              </ul>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.details.technicalSupport.length">
          <b-col>
            <b-card title="Technical Support">
              <b-list-group>
                <b-list-group-item>Session Time: {{ detail.details.technicalSupport[0].session.time }}</b-list-group-item>
                <b-list-group-item>Session Price: {{ detail.details.technicalSupport[0].session.price }}</b-list-group-item>
              </b-list-group>
              <p class="mt-2 font-weight-bold">
                Issues
              </p>
              <ul>
                <li
                  v-for="issue in detail.details.technicalSupport[0].issues"
                  :key="issue.id"
                >
                  {{ issue.name }} ({{ issue.average_time }})
                </li>
              </ul>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const reportsModule = createNamespacedHelpers('reports')
export default {
  data() {
    return {
      loader: false,
      detail: null,
    }
  },
  async mounted() {
    await this.getCustomerOrder()
  },
  methods: {
    ...reportsModule.mapActions(['FETCH_CUSTOMER_ORDER_DATA_BY_ID']),
    async getCustomerOrder() {
      try {
        this.loader = true
        const resp = await this.FETCH_CUSTOMER_ORDER_DATA_BY_ID(this.$route.params.id)
        this.detail = resp.data.data
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
